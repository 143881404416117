<template>
  <div>
    <label
      v-if="label"
      class="tw-block tw-py-2 tw-leading-normal"
      :for="name"
    >
      {{ label }}
    </label>
    <div
      :class="inputWrapperClass"
    >
      <slot />
    </div>
    <small
      class="tw-block tw-my-1 tw-h-5 error-message-color"
      :class="hideEmptyErrors && !errors.length ? 'tw-hidden' : 'tw-block'"
    >
      {{ errors[0] }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'InputWrapper',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() { return []; },
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideEmptyErrors: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputWrapperClass() {
      return [
        this.$style.wrapper,
        'tw-inline-block',
        'tw-transition-all',
        'tw-px-3',
        'tw-py-1.5',
        'tw-rounded-lg',
        'tw-text-base',
        'tw-font-normal',
        'tw-border',
        'focus-within:tw-shadow-lg',
        this.fullWidth ? 'tw-w-full' : undefined,
        this.disabled ? this.$style.disabled : undefined,
        this.errors.length ? this.$style.invalid : undefined,
      ];
    },
  },
};
</script>

<style module>
.wrapper {
  background-color: var(--input-background-color);
  border-color: var(--input-border-color);
  color: var(--text-darkest);
}

.wrapper:focus-within {
  border-color: var(--input-border-color-focus);
}

.disabled {
  background-color: var(--input-background-color-disabled);
  color: var(--text-regular);
}

.invalid {
  border-color: var(--saturated-red);
}
</style>
